import React from "react"
import theme from "styles/theme"
import InvisibleButton from "components/InvisibleButton"

const ArrowButton = (props) => (
  <InvisibleButton
    css={theme.expandFull({
      position: "relative",
      width: [52, 40, 28],
      height: [52, 40, 28],
      flex: "0 0 auto",
      color: theme.colors.extended.mint,
      transition: "transform 1s ease-out",
      ":after": {
        boxShadow: theme.shadows.buttonTight,
        borderRadius: "100%",
        content: "''",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 3,
        transition: "background 500ms",
      },
      ":hover": {
        transform: "scale(1.05) translateY(-1px)",
        ":after": {
          background: "rgba(255,255,255,0.15)",
        },
      },
    })}
    {...props}
  >
    <svg fill="none" viewBox="0 0 52 52">
      <circle cx="26" cy="26" r="26" fill="currentColor" />
      <path
        fill={theme.colors.floatingContainerBackground}
        d="M36.9 25.7l-9.5-9.5a.5.5 0 00-.7 0l-1.4 1.4c-.2.2-.2.5 0 .7l6.3 6.3h-16c-.4-.1-.6.2-.6.4v2c0 .3.2.5.5.5h16l-6.3 6.3c-.2.2-.2.5 0 .7l1.4 1.4c.2.2.5.2.7 0l9.5-9.5c.2-.2.2-.5.1-.7z"
      />
    </svg>
  </InvisibleButton>
)

export default ArrowButton
