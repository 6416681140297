import ArrowButton from "components/ArrowButton"
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const JobListings = ({ title, slug }) => {
  const {
    data: { listings },
  } = useStaticQuery(graphql`
    {
      data: allSanityJobPost {
        listings: nodes {
          _id
          title
          location {
            name
          }
          slug {
            current
          }
        }
      }
    }
  `)

  /**
   * Bail and return null if no listings are found
   */
  if (Array.isArray(listings) && !listings.length) {
    return null
  }

  return (
    <section
      id={slug}
      css={{
        padding: "80px 25px",
        [theme.mq.mobile]: {
          padding: "30px 25px",
        },
      }}
    >
      <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>

      <div
        css={{
          margin: "50px auto 0",
          maxWidth: 1278,
          [theme.mq.mobile]: { marginTop: 20 },
        }}
      >
        {listings.map(({ _id, ...jobPost }) => (
          <JobListing key={_id} {...jobPost} />
        ))}
      </div>
    </section>
  )
}

export default JobListings

JobListings.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
}

const JobListing = ({ title, location, slug }) => (
  <Link
    to={slug.current}
    css={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "14px 30px",
      background: theme.colors.floatingContainerBackground,
      color: theme.colors.primaryText,
      marginBottom: 10,
      textDecoration: "none",
      boxShadow: theme.shadows.tight,
      borderRadius: 4,
      ":last-child": {
        marginBottom: 0,
      },
      [theme.mq.mobile]: {
        padding: "10px 12px",
      },
    }}
  >
    <div css={{ ...theme.p2, fontWeight: 500, marginRight: "0.75em" }}>
      {title}
    </div>

    {location && (
      <div
        css={{
          flexGrow: "1",
          opacity: 0.6,
          [theme.mq.mobilePortrait]: {
            display: "none",
          },
        }}
      >
        {location.name.split(/, ?/).slice(-1)[0]}
      </div>
    )}

    <ArrowButton width={52} />
  </Link>
)

JobListing.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
  slug: PropTypes.shape({
    current: PropTypes.string.isRequired,
  }).isRequired,
}

export const query = graphql`
  fragment JobListingsBlock on SanityJobListings {
    title
  }
`
